import React, { useState, useRef, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer';
import Slider from "react-slick";
import axios from 'axios';

const BusinessPage = () => {

    const [qaPairs, setQAPairs] = useState([]);

    const containerRef = useRef(null);

    useEffect(() => {
        scrollToBottom();
    }, [qaPairs]);


    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };

    const Api_URL = "https://console.dialogflow.com/v1/integrations/messenger/webhook/aa98baf8-fcb8-4df2-9e22-d872d7c6fa25/sessions/webdemo-891c5df0-4418-4813-a7cd-b895a7a36e9b?platform=webdemo";

    const [chat, setChat] = useState();


    const [show, setShow] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setChat(value);
    }

    const handleChat = async () => {

        const data = {
            queryInput: {
                text: {
                    text: chat,
                    languageCode: "en"
                }
            }
        };


        if (chat !== '') {
            try {
                const response = await axios.post(Api_URL, data, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                // Remove leading characters if present
                let jsonResponse = response.data;
                if (jsonResponse.startsWith(')]}\'')) {
                    jsonResponse = jsonResponse.substring(4); // Remove the first 4 characters
                }

                // Parse the JSON
                const responseData = JSON.parse(jsonResponse);

                // Now you can access responseId
                const ansText = responseData.queryResult.fulfillmentText;

                const queryText = responseData.queryResult.queryText;

                const newQAPair = {
                    question: queryText,
                    answer: ansText
                };

                setQAPairs([...qaPairs, newQAPair]);

                // setQus(queryText);

                // setAns(ansText);


                setChat('');

            } catch (error) {
                console.error('Error:', error);
            }
        }
    }


    const handleShow = () => {
        setShow(!show)
        setQAPairs([]);
        setChat('');
    }



    return (
        <>
            <Header />
            <div className='main-content'>
                <div className='banners'>
                    <div className='container'>
                        <div className='ban_c'>
                            <div className='ban_lft col-md-7'>
                                <h1>Welcome to Probinar: Business Edge, Career Bridge!</h1>
                                <p>At Probinar, we're an edu-tech company focused on making learning simple and accessible. Our goal is to help you gain the skills you need to thrive in today's digital world. With Probinar, learning is easy and enjoyable. you'll discover a world of opportunities right at your fingertips</p>
                                <ul>
                                    <li><strong>3000+</strong><span>staffs</span></li>
                                    <li><strong>7000+</strong><span>students enrolled</span></li>
                                </ul>
                            </div>
                            <div className='ban_rgt col-md-5'>
                                <img src='images/banrgt.png' alt='banner-right' draggable="false"></img>
                            </div>
                        </div>


                    </div>
                </div>
                <div className='whychprobi'>
                    <div className='container'>
                        <h2>Why Choose Probinar?</h2>
                        <div className='probi_bx'>
                            <div className='probi_c '>
                                <span className='imp'><img src='images/educators.svg' alt='Expert Educators' draggable="false"></img></span>
                                <h3>Expert Educators</h3>
                                <p>Learn from expert instructors for a personalized, top-notch education</p>
                            </div>
                            <div className='probi_c '>
                                <span className='imp'><img src='images/partnership.svg' alt='partnership' draggable="false"></img></span>
                                <h3>Business Partnership Opportunities</h3>
                                <p>Join forces with Probinar for expanded education, exclusive benefits, and financial success.</p>
                            </div>
                            <div className='probi_c'>
                                <span className='imp'><img src='images/range.svg' alt='range' draggable="false"></img></span>
                                <h3>Wide Range of Courses</h3>
                                <p>Discover 30+ diverse courses for all skill levels, from tech to soft skills</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='probi_busi'>
                    <div className='container'>
                        <div className='probus row'>
                            <div className='probus_lft col-md-6'>
                                <img src='images/handshake.png' alt='Probinar Business Partner Program' draggable="false"></img>
                            </div>
                            <div className='probus_rgt  col-md-6'>
                                <h2>Probinar Business Partner Program</h2>
                                <p>Join forces with Probinar and become a part of our thriving business partner program. Whether you're an educational institution, a corporate training provider, or an individual entrepreneur, we welcome you to collaborate with us and leverage our expertise and resources to drive mutual success. As a Probinar business partner, you'll gain access to exclusive benefits, resources, and support to help you succeed in today's competitive market</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='excellan'>
                    <div className='container'>
                        <h2>Our Commitment to Excellence</h2>
                        <div className='excell row'>
                            <div className='excelbx col-md-6'>
                                <div className='ex_flx'>
                                    <span><img src="images/ima.svg" alt='ima' draggable="false"></img></span>
                                    <div className='ex_cont'>
                                        <h3>Top Quality Education</h3>
                                        <p>Probinar is all about providing the best education possible from the very start.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='excelbx col-md-6'>
                                <div className='ex_flx'>
                                    <span><img src="images/imb.svg" alt='ima' draggable="false"></img></span>
                                    <div className='ex_cont'>
                                        <h3>New and Exciting Learning</h3>
                                        <p>We're always coming up with new and fun ways to help you learn.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='excelbx col-md-6'>
                                <div className='ex_flx'>
                                    <span><img src="images/imc.svg" alt='ima' draggable="false"></img></span>
                                    <div className='ex_cont'>
                                        <h3>Great Team</h3>
                                        <p>Our team of over 3000+ experts is here to make sure you get the support and guidance you need.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='excelbx col-md-6'>
                                <div className='ex_flx'>
                                    <span><img src="images/imd.svg" alt='ima' draggable="false"></img></span>
                                    <div className='ex_cont'>
                                        <h3>Helping You Grow</h3>
                                        <p>Our goal is to help you become the best version of yourself through education and teamwork</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='chat-bot' onClick={handleShow}>
                    <img src='images/c-bot.png' alt='' width={70} height={60} />
                </div>



                {show ? (
                    <div className='chat'>
                        <div className='chat-text'>
                            <div className='chat-title'>
                                <p>Probinar Chat</p>
                            </div>
                            <div className='inpt_area'>
                                <div>

                                    <div className='msgs' ref={containerRef}>
                                        {qaPairs.length > 0 ? (
                                            qaPairs.map((pair, index) => (
                                                <div key={index}>
                                                    <p>Question: {pair.question}</p>
                                                    <p className='muth'>Answer: {pair.answer}</p>
                                                </div>
                                            ))

                                        )
                                            :
                                            (
                                                <p>Need any Help ?</p>
                                            )


                                        }
                                    </div>

                                    <div className='chat-in'>

                                        <input
                                            type='text'
                                            className='chat_inpt'
                                            placeholder='Your Questions'
                                            name='chat'
                                            value={chat}
                                            onChange={handleChange}
                                            maxLength={100}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleChat();
                                                }
                                            }}
                                        />

                                        <button className='btn btn-light' onClick={handleChat}><img src='images/send-btn.png' /></button>

                                    </div>

                                        {/* <img src='images/loader.gif' alt='' height={60} width={60} /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                    </>
                )}


            </div>

            <Footer />
        </>
    )
}

export default BusinessPage