import React, { useState } from 'react'
import Header from './Header'
import { register } from '../Yup';
import Yup from '../Yup';
import { Apiservices } from '../ApiServices';
import { postData, postSignData } from './ApiInstense';
import { toast } from 'react-hot-toast';

import Loader from 'react-js-loader';


import { useNavigate, Link } from "react-router-dom";

const Theform = () => {

   const navigate = useNavigate();

   const [loading, setLoading] = useState(false);


   const [errors, setErrors] = useState({});

   const [formData, setFormData] = useState({
      name: "",
      phone: "",
      email: "",
      occupation: "",
      age: "",


   });
   const handleBlur = (e) => {
      const { name } = e.target;
      register
         .validateAt(name, { [name]: formData[name] })
         .then(() => {
            setErrors({ ...errors, [name]: "" });
         })
         .catch((error) => {
            setErrors({ ...errors, [name]: error.message });
         });
   };

   const handleChange = (e) => {
      const { name, value } = e.target;


      console.log(name, value);

      let sanitizedValue = value;
      let spaceError = "";

      if (name === "name") {
         sanitizedValue = value.trimStart();
      }

      if (name === "occupation") {

         console.log(" >>>>>>>>>>>>>>> ", value)
         sanitizedValue = value;
      }


      if (name !== "name") {
         sanitizedValue = sanitizedValue.replace(/\s/g, "");
      }
      if (name === "phone" || name === "age") {
         sanitizedValue = sanitizedValue.replace(/\D/g, "");
      } else {
         sanitizedValue = sanitizedValue.replace(/\s/g, "");
      }

      setFormData({ ...formData, [name]: sanitizedValue });
      setErrors({ ...errors, [`${name}SpaceError`]: spaceError }); // Set space error separately for each field

      register
         .validateAt(name, { [name]: sanitizedValue })
         .then(() => {
            setErrors({ ...errors, [name]: "" });
         })
         .catch((error) => {
            setErrors({ ...errors, [name]: error.message });
         });
   };
   const handleOnlyLetters = (e) => {
      if (!/^[a-zA-Z\s.]*$/.test(e.key)) {
         e.preventDefault();
      }
   };
   const handleSubmit = async (e) => {
      e.preventDefault();

      const emptyFields = {};
      const labelMapping = {
         name: "Name",
         email: "Email",
         occupation: "Occupation",
         age: "Age",
         phone: "Phone Number"
      };

      Object.entries(formData).forEach(([key, value]) => {
         if (!value) {
            const label = labelMapping[key] || key;
            emptyFields[key] = `${label} is Required`;
         }
      });


      setErrors({ ...errors, ...emptyFields })



      if (Object.keys(emptyFields).length > 0) {
         return;
      }


      try {
         // Proceed with schema validation
         await register.validate(formData, { abortEarly: false })


         setErrors({});
         handlePost();
      } catch (error) {

         const validationErrors = {};
         error.inner.forEach((err) => {
            validationErrors[err.path] = err.message;
         });
         setErrors(validationErrors);
      }
   };
   const handlePost = async () => {

      setLoading(true);

      const response = await postSignData(Apiservices.BUSSINESS_USER_REGISTER, formData);

      if (response.status) {
         setFormData((prev) => ({
            ...prev,
            name: "",
            phone: "",
            email: "",
            occupation: "",
            age: "",
         }
         ))

         toast.success(response.message)

         setLoading(false);

         navigate('/BusinessPartnerProgram')


      } else {
         toast.error(response.message)
         setFormData((prev) => ({
            ...prev,
            name: "",
            phone: "",
            email: "",
            occupation: "",
            age: "",
         }
         ))
         setLoading(false);

      }

   }

   return (

      <>
         {loading ? (

            <Loader type="box-rectangular" bgColor={"#1A8F5C"} size={50} />

         ) : (

            <>
               <Header />
               <div className='main-content'>
                  <div className='frm_con'>
                     <div className='container'>
                        <div className='frm_flx'>
                           <div className='probibusi'>
                              <h1>Welcome to Probinar: Business Edge, Career Bridge!</h1>
                              <p>We're excited to invite you to join us on a journey of mutual growth and success. Let's grow together!</p>
                              <ul>
                                 <li><strong>3000+</strong><span>staffs</span></li>
                                 <li><strong>7000+</strong><span>students enrolled</span></li>
                              </ul>
                              <p>Start your journey to success with us today!</p>
                           </div>
                           <div className='frmc col-md-7'>
                              <div className='container'>
                                 <div className="inptbx">
                                    <label>Name</label>
                                    <input type='text'
                                       autoComplete='off'
                                       name='name'
                                       id="name"
                                       value={formData.name}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       onKeyDown={handleOnlyLetters}
                                       maxLength={50}
                                       placeholder='Name'></input>
                                    <span id='error' style={{ color: 'red' }}>{errors.name}</span>
                                 </div>
                                 <div className="inptbx">
                                    <label>Phone Number</label>
                                    <input
                                       autoComplete='off'
                                       type='text'
                                       placeholder='Phone Number'
                                       name='phone'
                                       value={formData.phone}
                                       onChange={handleChange}
                                       maxLength={10}
                                       onBlur={handleBlur}


                                    ></input>
                                    <span id='error' style={{ color: 'red' }}>{errors.phone}</span>
                                 </div>
                                 <div className="inptbx">
                                    <label>Email</label>
                                    <input
                                       autoComplete='off'
                                       type='text'
                                       placeholder='Email'
                                       name='email'
                                       value={formData.email}
                                       onChange={handleChange}
                                       onBlur={handleBlur}

                                    ></input>
                                    <span id='error'>{errors.email}</span>
                                 </div>
                                 <div className='sel'>
                                    <label>Occupation</label>
                                    <select
                                       autoComplete='off'

                                       className='sel_cont'
                                       name='occupation'
                                       value={formData.occupation}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       placeholder="occupation"
                                    >
                                       <option value="" disabled>Select your occupation</option> {/* Add a default option */}
                                       <option value="House wife">House wife</option>
                                       <option value="Student">Student</option>
                                       <option value="Working_Professional">Working Professional</option>
                                       <option value="Business">Business</option>
                                       <option value="others">Others</option>
                                    </select>
                                    <span id='error'>{errors.occupation}</span>

                                 </div>
                                 <div className="inptbx">
                                    <label>Age</label>
                                    <input
                                       autoComplete='off'
                                       type='text'
                                       placeholder='Age'
                                       name='age'
                                       value={formData.age}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       maxLength={2}

                                    >
                                    </input>
                                    <span id='error' style={{ color: 'red' }}>{errors.age}</span>
                                 </div>
                                 <button type='button' className='bt_cont' onClick={handleSubmit}>Next</button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </>

         )}
      </>
   )
}

export default Theform