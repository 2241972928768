import React from 'react'
import * as Yup from "yup";


export const register = Yup.object().shape({
    name: Yup.string()
        .required("Name is Required")
        .matches(/^[A-Za-z\s]+$/, "Numbers and Symbols are not allowed")
        .min(3, "Name must be at least 3 characters")
        .max(50, "Name must be at most 50 characters"),
      
    email: Yup.string()
        .required("Email is Required")
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),

    phone: Yup.string()
        .required("Phone Number is Required")
        .matches(/^\+?[1-9]\d{9,14}$/, "Invalid phone number format") // Ensures at least 10 digits
        .test('len', 'Phone number must be at least 10 digits', val => val.replace(/\D/g, '').length >= 10),

    occupation: Yup.string()
        .required("Occupation is required"),

        age: Yup.number()
        .transform((value, originalValue) => originalValue === '' ? null : value)
        .required("Age is required")
        .min(18, "Age must be at least 18")
        .max(100, "Age must be at most 100")
});
    


export const designation = Yup.object().shape({
    name: Yup.string()
        .required("Name is Required")
        .matches(/^[A-Za-z\s]+$/, "Numbers and Symbols are not allowed")
        .min(3, "Name must be at least 3 characters")
        .max(50, "Name must be at most 50 characters"),
      
    email: Yup.string()
        .required("Email is Required")
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),

        phone: Yup.string()
        .required("Phone Number is Required")
        .matches(/^\+?[1-9]\d{9,14}$/, "Invalid phone number format") // Ensures at least 10 digits
        .test('len', 'Phone number must be at least 10 digits', val => val.replace(/\D/g, '').length >= 10),
    


        location: Yup.string().required("Location is Required"),
        ctc: Yup.string().required("CTC is Required"), 
        gender: Yup.string().required("Gender is Required"), 
        experience: Yup.string().required("Experience is Required"), 
        // resume: Yup.mixed().required("Resume is Required")
});
    
    
    
    
    function YupMethod() {
    return (
        <div>Yup</div>
    )
}

export default YupMethod