import React, { useState } from 'react';
import Header from './Header';
import Accordion from 'react-bootstrap/Accordion';
import Footer from './Footer';
import { designation } from '../Yup';
import { postData } from './ApiInstense';
import { Apiservices } from '../ApiServices';
import axios from 'axios';
import { useCustomEffect } from '../useEffectCommon/useCommonEffect';
import { postGetData } from '../../src/Componant/ApiInstense';
// import toast from 'react-hot-toast/headless';
import { toast } from 'react-hot-toast';
const CareerPage = () => {

  const [activeKey, setActiveKey] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    location: "",
    ctc: "",
    gender: "",
    experience: "",
    resume: null,
    jobTitle: "UI UX Developer",
    jobLocation: "Madurai",
    jobDescriptionTitle: "Content",
    jobDescriptionDetails: "Relative Description"
  });

  const [errors, setErrors] = useState({});
  const [jobList, setJobList] = useState([]);


  const getJob = async () => {
    const response = await postGetData(Apiservices.BUSSINESS_CREATEJOB_REGISTER)
    if (response.status) {
      console.log('response---', response.data);
      setJobList(response.data)

    } else {

    }


  }
  useCustomEffect(getJob)




  const handleChange = (e) => {
    console.log('e.target----', e.target._id);
    const { name, value, files } = e.target;

    let sanitizedValue = value;
    let spaceError = "";


    if (name === "resume" && files) {
      const file = files[0];
      if (file.type === "application/pdf" || file.type === "application/msword") {
        setErrors({ ...errors, resume: "" });
        setFormData({ ...formData, resume: file });
        return;
      }
      else {
        setFormData({ ...formData, resume: null });
        setErrors({ ...errors, resume: "Invalid Document Type" });
        return;
      }
    }

    if (name === "name") {
      sanitizedValue = sanitizedValue.replace(/[^a-zA-Z\s]/g, "").trimStart();
    }

    if (name === "phone" || name === "ctc") {
      sanitizedValue = sanitizedValue.replace(/\D/g, "");
    }

    if (name === "experience" || name === "location") {
      sanitizedValue = sanitizedValue.replace(/\.{2,}/g, ".");
      sanitizedValue = sanitizedValue.replace(/[^a-zA-Z0-9\s.]/g, "").trimStart();
    }


    if (name === "email") {
      sanitizedValue = sanitizedValue.replace(/[^a-zA-Z0-9@.\s]/g, "").trimStart();
    }

    setFormData({ ...formData, [name]: sanitizedValue });
    setErrors({ ...errors, [`${name}SpaceError`]: spaceError });

    designation
      .validateAt(name, { [name]: sanitizedValue })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "", [`${name}SpaceError`]: spaceError }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message, [`${name}SpaceError`]: spaceError }));
      });
  };

  const handleBlur = (e) => {
    const { name } = e.target;

    if (name === 'resume') {
      // No need to validate file inputs onBlur
      return;
    }

    // Validate other fields using Yup schema
    designation
      .validateAt(name, { [name]: formData[name] })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
      });
  };


  const handleOnlyLetters = (e) => {
    if (!/^[a-zA-Z\s.]*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleSubmit = async (jobData) => {
    // e.preventDefault();

    console.log("form", jobData);
    const emptyFields = {};
    const labelMapping = {
      name: "Name",
      email: "Email",
      location: "Location",
      phone: "Phone Number",
      ctc: "CTC",
      gender: "Gender",
      experience: "Experience",
      resume: "Resume"
    };

    Object.entries(formData).forEach(([key, value]) => {
      if (!value) {
        const label = labelMapping[key] || key;
        emptyFields[key] = `${label} is Required`;
      }
    });

    setErrors({ ...errors, ...emptyFields });

    if (Object.keys(emptyFields).length > 0) {
      console.log("Form contains empty fields:", emptyFields);
      return;
    }

    try {
      await designation.validate(formData, { abortEarly: false });
      setErrors({});
      console.log("Validation succeeded");
      handlePost(jobData);
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
      console.log("Validation errors:", validationErrors);
    }
  };

  const handlePost = async (jobData) => {
    const formDataToSend = new FormData();

    // Append the file
    if (formData.resume) {
      formDataToSend.append('resume', formData.resume);
    }

    console.log("jobs--", jobData);

    // Append other form data
    const postForm = {
      name: formData.name,
      phone: formData.phone,
      email: formData.email,
      location: formData.location,
      ctc: formData.ctc,
      gender: formData.gender,
      experience: formData.experience,
      jobTitle: jobData.jobTitle,
      jobLocation: jobData.jobLocation,
      jobDescriptionTitle: jobData.jobDescriptionTitle,
      jobDescriptionDetails: jobData.jobDescriptionDetails
    };

    // Append other fields to formData
    Object.entries(postForm).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });

    try {
      const response = await postData(Apiservices.BUSSINESS_APPLICATION_REGISTER, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status) {

        setFormData(() => ({
          name: "",
          email: "",
          location: "",
          phone: "",
          ctc: "",
          gender: "",
          experience: "",
          resume: ""
        }));
        toast.success(response.message)

      }
      else {
        setFormData(() => ({
          name: "",
          email: "",
          location: "",
          phone: "",
          ctc: "",
          gender: "",
          experience: "",
          resume: ""
        }));

        toast.error(response.message)

      }

    } catch (error) {
      // console.error("Post failed with error:", error);
      // toast.error("GEUREHUR")

    }
  };

  const handleAccordionClick = (key) => {

    setFormData(() => ({
      name: "",
      email: "",
      location: "",
      phone: "",
      ctc: "",
      gender: "",
      experience: "",
      resume: ""
    }));


    setErrors({})

    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <>

      <Header />

      <div className='main-content'>
        <div className='banner'>
          <div className='container'>
            <div className='bans_c'>
              <h1>Join Our Team and Grow Your Career with Us!</h1>
              <p>
                Explore exciting career opportunities, grow your skills, and make a difference with us. Your future starts here. Apply now and become a part of our success story!
              </p>
              {/* <ul className='careerpage'>
                <li><button type="button" className='bt_cont'>Join the team</button></li>
                <li><button type="button" className='bt_cont'>Culture</button></li>
              </ul> */}
            </div>
          </div>
        </div>
        <div className='career_cont'>
          <div className='container'>
            <div className='career_page'>

              <div className='job_det'>

                <Accordion activeKey={activeKey} onSelect={handleAccordionClick} defaultActiveKey="0">
                  {jobList.map((data, index) => {
                    return (

                      <Accordion.Item eventKey={index.toString()} key={data._id}>
                        <Accordion.Header className='title' onClick={(e) => handleAccordionClick(index.toString())} ><p><strong>{data.jobTitle}</strong><span>{data.jobLocation}</span></p></Accordion.Header>
                        <Accordion.Body>
                          <div className='jbd'>
                            <div className='jbd_lft'>
                              <strong>{data.jobDescriptionTitle}</strong>
                              <p>{data.jobDescriptionDetails}</p>
                            </div>
                            <div className='jbd_rgt'>
                              <strong>Application Form</strong>
                              <div className="inptbx">
                                <label>Name</label>
                                <input
                                  name='name'
                                  id="name"
                                  value={formData.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete='off'
                                  maxLength={50}
                                // onKeyDown={handleOnlyLetters}
                                ></input>
                                <span id='error' style={{ color: 'red' }}>{errors.name}</span>
                              </div>
                              <div className="inptbx">
                                <label>Phone Number</label>
                                <input type='text'
                                  name='phone'
                                  value={formData.phone}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  maxLength={10}
                                  autoComplete='off'
                                ></input>
                                <span id='error' style={{ color: 'red' }}>{errors.phone}</span>
                              </div>
                              <div className="inptbx">
                                <label>Email</label>
                                <input
                                  type='text'
                                  name='email'
                                  value={formData.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete='off'
                                  maxLength={50}
                                ></input>
                                <span id='error' style={{ color: 'red' }}>{errors.email}</span>
                              </div>
                              <div className="inptbx">
                                <label>Location</label>
                                <input type='text'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={formData.location}
                                  name='location'
                                  autoComplete='off'
                                  maxLength={50}
                                ></input>
                                <span id='error' style={{ color: 'red' }}>{errors.location}</span>
                              </div>
                              <div className="inptbx">
                                <label>CTC</label>
                                <input type='text'
                                  name='ctc'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={formData.ctc}
                                  autoComplete='off'
                                  maxLength={10}
                                ></input>
                                <span id='error' style={{ color: 'red' }}>{errors.ctc}</span>
                              </div>
                              <div className='sel'>
                                <span>Gender</span>
                                <select
                                  className='sel_cont'
                                  value={formData.gender}
                                  name='gender'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete='off'
                                >
                                  <option value="" disabled>Gender</option>
                                  <option value="Male" >Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Others">Others</option>
                                </select>
                                <span id='error' style={{ color: 'red' }}>{errors.gender}</span>
                              </div>
                              <div className="inptbx">
                                <label>Experience</label>
                                <input type='text'
                                  name='experience'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={formData.experience}
                                  autoComplete='off'
                                  maxLength={25}
                                ></input>
                                {/* <select
                                className='sel_cont'
                                placeholder="Experience"
                                name='experience'
                                value={formData.experience}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option value="1-2 Year" disabled></option>
                                <option value="2-3 Year">2-3 Year</option>
                                <option value="1-2 Year">1-2 Year</option>
                                <option value="3-5 Year">3-5 Year</option>
                              </select> */}
                                <span id='error' style={{ color: 'red' }}>{errors.experience}</span>
                              </div>
                              <div className="inptbx files">
                                <span>Upload</span>
                                <label className='upl'>
                                  <input
                                    type='file'
                                    placeholder='upload'
                                    name='resume'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  ></input>
                                  <p>{formData.resume ? formData.resume.name : 'Upload Your Resume'}</p>


                                </label>
                                <span id='error' style={{ color: 'red' }}>{errors.resume}</span>
                              </div>
                              <button type='button' className='bt_cont' onClick={() => handleSubmit(data)}>Apply</button>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  })}

                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CareerPage;
