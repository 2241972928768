
import axios from 'axios';
import { Apiservices } from '../ApiServices';



// const axiosInstance = axios.create({
//   baseURL: Apiservices.BACKEND_LOCALHOST_SERVER,
//   // timeout: 90000,
// });


// axiosInstance.interceptors.request.use(
//   (config) => {
//     const token = Cookies.get("jwtToken");
//     if (token) {
//       config.headers.Authorization = `${token}`;
//     }
    
//     return config;
//   },
//   (error) => {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

// axiosInstance.interceptors.response.use(
//   (response) => {
//     if (response.data.statusCode === 700) {
//       localStorage.removeItem('jwtToken');
//       window.redirect("/login")
//     }
//     return response;
//   },
// );

// axiosInstance.interceptors.response.use(
//   (response) => {
//     if (response.data && response.data.statusCode === 700) {
//       console.log("Token expired. Attempting token refresh...");

//       if (!response.config._retry) {
//         response.config._retry = true;

//         return axiosInstance
//           .post(`/user/refreshToken`)
//           .then((refreshResponse) => {
//             if (refreshResponse.data.token) {
//               console.log("Token refreshed successfully.");
//               const token=refreshResponse.data.token;

//               Cookies.set("jwtToken",token); 

             
//               axiosInstance.defaults.headers.common[
//                 "Authorization"
//               ] = `${refreshResponse.data.token}`;
//               response.config.headers[
//                 "Authorization"
//               ] = `${refreshResponse.data.token}`;

//               return axiosInstance(response.config);
//             } else {
//               console.error(
//                 "Refresh token response missing token:",
//                 refreshResponse
//               );
        
//             }
//           })
//           .catch((refreshError) => {
//             console.error("Error refreshing token:", refreshError);
     
//             Cookies.remove("jwtToken");
//             window.location.href = "/auth";
//             return Promise.reject(refreshError);
//           });
//       } else {
//         return Promise.reject(response);
//       }
//     }

//     return response;
//   },
//   (error) => {
//     console.error("Error making request:", error);
//     return Promise.reject(error);
//   }
// );
const axiosInstance = axios.create({
  
    baseURL: Apiservices.BACKEND_LOCALHOST_SERVER,
   
  });


const handleError = (error) => {

  console.error('Request failed:', error);
  throw error;
};

// POST request
export const postData = async (endpoint, data) => {
  // console.log(Apiservices.BACKEND_CAREERS_SERVER+endpoint, data);

  // const gettoken = Cookies.get("jwtToken");

  console.log("Get Token Post ----- ", endpoint, data);


  try { 
    const response = await axiosInstance.post(`${Apiservices.BACKEND_CAREERS_SERVER + endpoint}`, data);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};
export const postSignData = async (endpoint, data) => {


  // console.log("Get Token Post ----- ", endpoint, data);


  try { 
    const response = await axiosInstance.post(`${Apiservices.BACKEND_CAREERS_SERVER + endpoint}`, data);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// GET request
export const getData = async (endpoint) => {
  try {
    const response = await axiosInstance.get(`${Apiservices.BACKEND_CAREERS_SERVER + endpoint}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// PUT request
export const putData = async (endpoint, data) => {
  try {
    const response = await axiosInstance.put(`${Apiservices.BACKEND_CAREERS_SERVER + endpoint}`, data);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// DELETE request
export const deleteData = async (endpoint, data) => {
  try {
    const response = await axiosInstance.delete(`${Apiservices.BACKEND_CAREERS_SERVER + endpoint}`, data);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};
export const deleteBankData = async (endpoint, id) => {
  try {
    const response = await axiosInstance.delete(`${Apiservices.BACKEND_CAREERS_SERVER}${endpoint}/${id}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const postGetData = async (endpoint) => {
  try {
    const response = await axiosInstance.post(`${Apiservices.BACKEND_CAREERS_SERVER + endpoint}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export default axiosInstance;
