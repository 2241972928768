
import Header from './Componant/Header';
import Theform from './Componant/Theform';
import Landing from './Componant/Landing';
import { Routes, Route } from "react-router-dom";
import './Style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CareerPage from './Componant/CareerPage';
// import Bussiness from './Componant/Bussiness';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Business from './Componant/Business';
import Team from './Componant/Team';
import Teamb from './Componant/Teamb';
import Gallary from './Componant/Gallary';

import { Toaster } from 'react-hot-toast';
function App() {
  return (
    <div className="App">
      <Toaster toastOptions={{ duration: 4000 }} />
      <Routes>
       <Route path='*' element={<Landing/>} />
       <Route path='/' element={<Landing/>} />
       <Route path='/Bussiness-Partner' element={<Theform/>} />
       <Route path='/CareerPage' element={<CareerPage/>} />
       <Route path='/BusinessPartnerProgram' element={<Business/>} />
       <Route path='/Teams' element={<Team/>} />
       <Route path='/teamb' element={<Teamb/>} />
       <Route path='/Gallery' element={<Gallary/>} />
      </Routes>
    </div>
  );
}

export default App;


