import React from 'react'
import Header from './Header'
import Footer from './Footer'
import ReactPlayer from 'react-player';
import Slider from "react-slick";


const Gallary = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
      <Header/>
      <div className='main-content'>
      <div className='banner gall'>
          
                 <div className='ban_im'>
                 <ReactPlayer
                        url="https://ultrapro-videos.s3.amazonaws.com/ULTRA+PRO.mp4
                        "
                        playing={true}
                      
                        muted={true} // mute the video to ensure autoplay
                        loop={true}
                        />
                 </div>
                <div className='bans_c'>
                   <h1>Our Journey in Images</h1>
                   <p>Welcome to our gallery! Explore a collection of images showcasing our company's journey, team moments, and milestones. Experience our story through these snapshots.</p>
                   
                </div>
             </div>

        
        <div className='gall'>
           <div className='container'>
             <div className='gall_cont'>
                 <h2>Gallery</h2>
                 <div className="slider-container">
                     <Slider {...settings}>
                    <div><img src='images/capa.jpg' alt='capbin' draggable="false"></img></div>
                    <div><img src='images/capb.jpg' alt='capbin' draggable="false"></img></div>
                    <div><img src='images/capc.jpg' alt='capbin' draggable="false"></img></div>
                    <div><img src='images/capc.jpg' alt='capbin' draggable="false"></img></div>
                    <div><img src='images/capd.jpg' alt='capbin' draggable="false"></img></div>
                    <div><img src='images/cape.jpg' alt='capbin' draggable="false"></img></div>
                    <div><img src='images/capf.jpg' alt='capbin' draggable="false"></img></div>
                    <div><img src='images/capg.jpg' alt='capbin' draggable="false"></img></div>
                    <div><img src='images/capi.jpg' alt='capbin' draggable="false"></img></div>
                    <div><img src='images/capj.jpg' alt='capbin' draggable="false"></img></div>
                   </Slider>
                 </div>
             </div>
           </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default Gallary