import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Slider from "react-slick";
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

const Business = () => {
   var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 3,
               infinite: true,
               dots: true
            }
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               initialSlide: 2
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1
            }
         }
      ]
   };
   return (
      <>
         <Header />
         <div className='main-content'>
            <div className='ban_busi'>
               <div className='ban_mob'>
                  <h1>Probinar Business Partner Program </h1>
                  <p> Affiliate with Next-Gen Learning-Boost your earnings by joining the Probinar Business Partner Program and modernize business transactions</p>
                  <div className='ban_btn'>
                  <ul className='careerpage'>
                     <li><Link to={'/Bussiness-Partner'}><button type="button" className='bt_cont'>Join the team</button></Link></li>
                     <li><Link to={'https://probinar.online/marketer.html'} target='_blank'><button type="button" className='bt_cont'>Login</button></Link></li>
                  </ul>
               </div>
               </div>
               
            </div>
            <div className='probi_busi busipro'>
               <div className='container'>
                  <div className='probus row'>
                     <div className='probus_lft col-lg-6'>
                        <img src='https://images-ultra.s3.amazonaws.com/business_parner.png' alt='Business Partner Program'></img>
                     </div>
                     <div className='probus_rgt  col-lg-6'>
                        <h2>What is Probinar’s Business partner program?</h2>
                        <p>Probinar's Business Partner Program is a collaborative initiative designed for individuals and businesses interested in promoting Probinar's diverse range of online courses. As an edu-tech company, Probinar offers an extensive catalog of educational programs. Through the Business Partner Program, affiliates have the opportunity to earn commissions and more exciting offers by referring customers to enroll in Probinar's courses.Probinar welcomes individuals from all backgrounds to join as affiliates and start a journey of empowering learners and transforming lives through education.</p>
                     </div>
                  </div>
               </div>
            </div>
            <div className='commission_benift'>
               <div className='container'>
                  <h2>Commissions and exciting Benefits</h2>
                  <strong>Reach New Heights</strong>
                  <p>This commission cycle continues, ensuring ongoing opportunities to grow your income with Probinar's Business Partner Program. Don't miss out – join us today!</p>
                  <div className='commi_bene'>
                     <div className="slider-container">
                        <Slider {...settings}>

                           <div className='probi_c '>
                              <span className='imp'><img src='images/bima.svg' alt='Boost your Income' draggable="false"></img></span>
                              <h3>Boost your Income</h3>
                              <p>Commission: 40% for ₹5000 turnover</p>
                           </div>
                           <div className='probi_c '>
                              <span className='imp'><img src='images/bimb.svg' alt='Double Your Rewards' draggable="false"></img></span>
                              <h3>Double Your Rewards</h3>
                              <p>Commission: 80% for ₹5000 - ₹10000 turnover</p>
                           </div>
                           <div className='probi_c '>
                              <span className='imp'><img src='images/bimc.svg' alt='Accelerate Your Earnings' draggable="false"></img></span>
                              <h3>Accelerate Your Earnings</h3>
                              <p>Commission: 40% for ₹10000 - ₹15000 turnover</p>
                           </div>
                           <div className='probi_c '>
                              <span className='imp'><img src='images/bimd.svg' alt='Maximize Your Profits' draggable="false"></img></span>
                              <h3>Maximize Your Profits</h3>
                              <p>Commission: 80% for ₹15000 - ₹20000 turnover</p>
                           </div>
                        </Slider>
                     </div>
                  </div>

               </div>
            </div>
            <div className='banners video_blk'>
               <div className='container'>
                  <div className='ban_c row'>
                     <div className='ban_lft col-lg-6'>
                        <p>Become a Probinar Affiliate: Register for Our Business Partner Program</p>

                     </div>
                     <div className='ban_rgt col-lg-6'>
                        <ReactPlayer
                           url="https://youtu.be/rViyjS0BKQk?si=oQ2FLgi0T16notNO"
                           controls
                           width="100%"
                           height="100%"
                           playing={true}
                                      
                        />
                     </div>
                  </div>


               </div>
            </div>
            <div className='banners video_blk sec_vid'>
               <div className='container'>
                  <div className='ban_c row'>
                     <div className='ban_lft col-lg-6'>
                        <p>Inside Probinar: Explore Our Workspace!</p>

                     </div>
                     <div className='ban_rgt col-lg-6'>
                        <ReactPlayer
                           url="https://youtu.be/rViyjS0BKQk?si=oQ2FLgi0T16notNO"
                           controls
                           width="100%"
                           height="100%"
                          
                        />
                     </div>
                  </div>


               </div>
            </div>
            <div className='probi_busi bsipro '>
               <div className='container'>
                  <div className='probus row'>
                     <div className='probus_lft col-lg-6'>
                        <img src='images/handshk.png' alt='Business Partner Program' draggable="false"></img>
                     </div>
                     <div className='probus_rgt  col-lg-6'>
                        <h2>Register in Our Probinar’s Business Partner Program</h2>
                        <strong className='probus_gr'>Become an Affiliate Now</strong>
                        <p>Join Probinar's Business Partner Program and start earning commissions for every successful referral. Simply click the "Join Us" button below, fill out the registration form, and begin promoting our courses to start building your income stream today!</p>
                        <Link to={'/Bussiness-Partner'}><button type="button" className='bt_cont'>Join the team</button></Link>
                     </div>

                  </div>
               </div>
            </div>
         </div>
         <Footer />
      </>
   )
}

export default Business