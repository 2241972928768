export const Apiservices = {
    BACKEND_LOCALHOST_SERVER: 'http://localhost:5000',
    BACKEND_USER_SERVER: 'users',
    BACKEND_CAREERS_SERVER: '/admin',
    

    BUSSINESS_USER_REGISTER:'/business-partner',
    BUSSINESS_APPLICATION_REGISTER:'/application-form',
    BUSSINESS_CREATEJOB_REGISTER:'/jobList'


    


    

    
}