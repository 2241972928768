import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {

    const handleScroll = () => {
        window.scrollTo(0, 0);
      };


    return (
        <footer>
            <div className='foots'>
                <div className='container'>
                    <div className='foot_img'>
                        <img src="images/flogo.svg" alt='flogo' draggable="false"></img>
                        <ul className='fmenu'>
                            <li><Link to="/" onClick={handleScroll}>Home</Link></li>
                            <li><Link to="/Teams" onClick={handleScroll}>Teams</Link></li>
                            <li><Link to="/Gallery" onClick={handleScroll}>Gallery</Link></li>
                            <li><Link to="/CareerPage" onClick={handleScroll}>Careers</Link></li>
                        </ul>
                        <ul>
                            <li>
                                <a href='https://x.com/i/flow/login?redirect_after_login=%2FProbinar_offl' target='_blank'>
                                    <img src='images/twitter.svg' alt='twitter' draggable="false"></img>
                                </a>
                            </li>
                            <li>
                                <a  href='https://www.linkedin.com/company/probinar/' target='_blank'>
                                    <img src='images/in.svg' alt='Linked In' draggable="false"></img>
                                </a>
                            </li>
                            <li>
                                <a  href='https://www.facebook.com/Probinar.offl' target='_blank'>
                                    <img src='images/fb.svg' alt='facebook' draggable="false"></img>
                                </a>
                            </li>
                            <li>
                                <a  href='https://www.youtube.com/@probinar' target='_blank'>
                                    <img src='images/utube.svg' alt='you tube' draggable="false"></img>
                                </a>
                            </li>
                            <li>
                                <a href='https://www.instagram.com/probinar.offl/' target='_blank'>
                                    <img src='images/insta.svg' alt='instagram' draggable="false"></img>
                                </a>
                            </li>
                        </ul>
                        <p> Copyright ©2024  probinar.online</p>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer
