import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

import { useLocation } from 'react-router-dom';

const Header = () => {

  const location = useLocation();

  return (
    <header>
      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
        <Container>
          <div className='head_cont'>
            <Navbar.Brand href="/"><img src="images/hlogo.svg" alt='hlogo' draggable="false"></img></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="menu">
                <Nav.Link href="/" className={location.pathname === '/' ? 'active' : ''}>Home</Nav.Link>
                <Nav.Link href="/Teams" className={location.pathname === '/Teams' ? 'active' : ''}>Teams</Nav.Link>
                <Nav.Link href='/Gallery' className={location.pathname === '/Gallery' ? 'active' : ''}>Gallery</Nav.Link>
                <Nav.Link href="/CareerPage" className={location.pathname === '/CareerPage' ? 'active' : ''}>Careers</Nav.Link>
                <Nav.Link href="/BusinessPartnerProgram" className={location.pathname === '/BusinessPartnerProgram' ? 'active' : ''}>Business Partner</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>

    </header>
  )
}

export default Header